* {
	box-sizing: border-box;
	font-family: 'Karla', sans-serif;
}

body {
	margin: 0;
	padding: 1.25em;
	background: #0b2434;
}

.title {
	font-size: 40px;
	margin: 0;
}

.instructions {
	font-family: 'Inter', sans-serif;
	font-weight: 400;
	margin-top: 0;
	text-align: center;
	max-width: 400px;
}

.container {
	background: #f5f5f5;
	height: 400px;
	max-width: 800px;
	border-radius: 0.5em;
	margin: 0 auto;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
}

.results-container {
	background: #f5f5f5;
	border-radius: 0.5em;
	margin: 0 auto;
	margin-top: 1em;
	max-width: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1em;
	line-height: 2;
}

.best-time {
	font-family: 'Inter', sans-serif;
	font-weight: 400;
	margin-top: 0;
	max-width: 400px;
	font-weight: 800;
	margin: 0;
	font-size: 1.125rem;
}

.user-results--after {
	font-family: 'Inter', sans-serif;
	font-weight: 400;
	max-width: 400px;
	margin: 0;
	text-align: center;
}

.die-face {
	width: 50px;
	height: 50px;
	border-radius: 0.5em;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.dice-container {
	display: grid;
	grid-template: auto auto / repeat(5, 1fr);
	gap: 1em;
	margin-bottom: 1em;
}

.die-num {
	font-size: 2rem;
}

.new-dice-btn {
	padding: 0.5em 2em;
	background: #5035ff;
	color: white;
	font-size: 1.25rem;
	font-weight: 700;
	letter-spacing: 2px;
	border-radius: 0.5em;
	border: none;
	text-align: center;
	cursor: pointer;
	transition: all 100ms;
}

.new-dice-btn:hover,
.new-dic-btn:focus {
	outline: 1px solid #5035ff;
	outline-offset: 2px;
}

.new-dice-btn:active {
	box-shadow: inset 5px 5px 10px -3px rgba(0, 0, 0, 0.7);
	transform: scale(0.95);
}

.accent {
	color: #5035ff;
}

.button-instructions--text {
	font-family: 'Inter', sans-serif;
	color: #5035ff;
	font-weight: 400;
	margin-top: 0;
	max-width: 400px;
	font-weight: 800;
	margin: 0;
	font-size: 0.9rem;
	margin-top: 0.75em;
}
